<template>
  <div class="wrapper">
    <div class="banner">
      <img src="../../../assets/img/home/banner.png" alt="" />
    </div>
    <!-- <div class="breadcrumb_box">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: orgPath }">{{
          breadcrumbName
        }}</el-breadcrumb-item>
        <el-breadcrumb-item>凭证归集</el-breadcrumb-item>
      </el-breadcrumb>
    </div> -->

    <div class="containertitle">
      <div class="container_title">凭证归集</div>
      <div class="container_dsc">
        云凭证夹归集服务为客户提供九部委最新文件种明确规定的九种数字凭证的归集。
        用户无需再登录多种平台或系统，只需登录数电通平台即可
      </div>
    </div>

    <div class="containerContent">
      <div class="containerbox containerbg1">
        <div class="containerName">增值税电子<br />普通发票</div>
      </div>
      <div class="containerbox containerbg2">
        <div class="containerName">增值税电子<br />专用发票</div>
      </div>
      <div class="containerbox containerbg3">
        <div class="containerName">数电票</div>
      </div>
      <div class="containerbox containerbg4">
        <div class="containerName">数电票（铁路<br />电子客票）</div>
      </div>
      <div class="containerbox containerbg5">
        <div class="containerName">数电票 (航空运<br />输电子客票行程单)</div>
      </div>
      <div class="containerbox containerbg6">
        <div class="containerName">财政电子票据</div>
      </div>
      <div class="containerbox containerbg7">
        <div class="containerName">电子非税收入<br />一般缴款书</div>
      </div>
      <div class="containerbox containerbg8">
        <div class="containerName">银行电子回单</div>
      </div>
      <div class="containerbox containerbg9">
        <div class="containerName">银行电子对账单</div>
      </div>
    </div>

    <div class="containertitle">
      <div class="container_title">凭证归档</div>
      <div class="container_dsc">
        云凭证夹凭证归档服务在凭证归集的基础上为客户提供凭证的识别鉴定、分类整理、归档储存，
        并对每封凭证进行“标签化”管理。记录每封
      </div>
    </div>

    <div class="managementBg"></div>
    <div class="container_dsc">
      实现电子档案“收、管、存、用”的全生命周期管理
      提升档案智能化水平，响应企业档案管理需求，助力企业数字化转型。
    </div>

    <div class="process_btn" @click="handleApply">立即体验</div>

    <!-- 一键办理弹出框 -->
    <el-dialog
      title="预留信息"
      :visible.sync="dialogVisible"
      width="25%"
      center
      :before-close="dialogColse"
    >
      <div class="dialog_head">
        <span>我们的客户经理将在1个工作日内与您联系！</span>
      </div>
      <div class="register_phone">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="10px"
          class="demo-ruleForm"
        >
          <el-form-item prop="reserveName">
            <el-input
              prefix-icon="el-icon-user"
              v-model="ruleForm.reserveName"
              placeholder="请输入称呼昵称"
            ></el-input>
          </el-form-item>
          <el-form-item prop="reservePhone">
            <el-input
              prefix-icon="el-icon-mobile-phone"
              v-model="ruleForm.reservePhone"
              maxlength="11"
              placeholder="请输入联系方式"
            ></el-input>
          </el-form-item>
          <el-form-item prop="reserveEntName">
            <el-input
              prefix-icon="el-icon-office-building"
              v-model="ruleForm.reserveEntName"
              placeholder="请输入企业名称"
            ></el-input>
          </el-form-item>
          <el-form-item prop="remark">
            <el-input
              type="textarea"
              :autosize="{ minRows: 2 }"
              v-model="ruleForm.remark"
              placeholder="备注......(200字以内)"
              maxlength="200"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')"
              >提交预约</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span> -->
    </el-dialog>

    <el-dialog :visible.sync="dialogSuccess" width="25%" center>
      <div class="success_icon">
        <img src="../../../assets/img/success.png" alt="" />
      </div>
      <div class="success_title">提交成功</div>
      <div class="success_content">
        <p>随后工作人员将与您联系，请保持电话畅通。</p>
        <p>咨询热线：<span>400-666-3369</span></p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addReserve } from "../../../api/reserve";
export default {
  components: {},
  props: {},
  data() {
    return {
      dialogVisible: false,
      registerPhone: "",
      formInline: {
        address: "太原",
        phone: "",
        acctName: "",
      },
      org: "",
      orgPath: "",
      breadcrumbName: "",

      dialogSuccess: false,
      dialogVisible: false,
      registerPhone: "",
      ruleForm: {
        reserveName: "",
        reservePhone: "",
        reserveEntName: "",
        remark: "",
      },
      rules: {
        reserveName: [
          { required: true, message: "请输入预约人名称", trigger: "blur" },
          { min: 2, max: 5, message: "长度在 2 到 5 个字符", trigger: "blur" },
        ],
        reservePhone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    onSubmit() {
      this.dialogVisible = true;
    },
    // 一键办理注册
    handleRegister() {
      this.dialogVisible = true;
    },
    // 体验信息提交
    handleApply() {
      this.dialogVisible = true;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ruleForm.reserveType = "04";
          addReserve(this.ruleForm).then((response) => {
            // if (response.code == 200) {
            //   // this.$modal.msgSuccess("意见办理注册预约成功");
            // }
            this.dialogSuccess = true;
            this.dialogVisible = false;
          });
        }
      });
    },
    dialogColse() {
      this.$refs.ruleForm.resetFields();
      this.dialogVisible = false;
    },
  },
  created() {
    this.org = this.$route.query.org;
    if (this.org == "home") {
      this.orgPath = "/";
      this.breadcrumbName = "首页";
    } else {
      this.orgPath = "/finance_1";
      this.breadcrumbName = "代理记账服务";
    }
  },
  mounted() {},
};
</script>
<style scoped>
.banner {
  width: 100%;
  height: 300px;
}

.banner img {
  display: block;
  width: 100%;
  height: 100%;
}

.breadcrumb_box {
  width: 1200px;
  padding: 30px 0 0;
  margin: 0 auto;
}

.container {
  padding: 60px;
}

.container_title {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  margin-top: 20px;
}

.container_dsc {
  margin: 20px auto;
  width: 40%;
  text-align: center;
  font-size: 18px;
  color: #848484;
}

.containerContent {
  width: 65%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}

.containerbox {
  height: 66px;
  width: 18%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 2%;
  text-align: center;
  margin: 1% 1%;
}

.containerName {
  width: 65%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.containerbg1 {
  background: url("../../../assets/img/home/certificateCollection/fpiao.png")
    no-repeat 50% 50%;
  background-size: 100% 100%;
}

.containerbg2 {
  background: url("../../../assets/img/home/certificateCollection/zd.png")
    no-repeat 50% 50%;
  background-size: 100% 100%;
}

.containerbg3 {
  background: url("../../../assets/img/home/certificateCollection/4058025_finance_dollar_money.png")
    no-repeat 50% 50%;
  background-size: 100% 100%;
}

.containerbg4 {
  background: url("../../../assets/img/home/certificateCollection/train.png")
    no-repeat 50% 50%;
  background-size: 100% 100%;
}

.containerbg5 {
  background: url("../../../assets/img/home/certificateCollection/icon_aviation.png")
    no-repeat 50% 50%;
  background-size: 100% 100%;
}

.containerbg6 {
  background: url("../../../assets/img/home/certificateCollection/piaoju.png")
    no-repeat 50% 50%;
  background-size: 100% 100%;
}

.containerbg7 {
  background: url("../../../assets/img/home/certificateCollection/cost-approval.png")
    no-repeat 50% 50%;
  background-size: 100% 100%;
}

.containerbg8 {
  background: url("../../../assets/img/home/certificateCollection/huidan.png")
    no-repeat 50% 50%;
  background-size: 100% 100%;
}

.containerbg9 {
  background: url("../../../assets/img/home/certificateCollection/zuopingoumai.png")
    no-repeat 50% 50%;
  background-size: 100% 100%;
}

.managementBg {
  background: url("../../../assets/img/home/certificateCollection/duobianxing.png")
    no-repeat 50% 50%;
  background-size: 100% 100%;
  width: 45%;
  height: 250px;
  margin: 5% auto;
}

.process_btn {
  width: 132px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 28px;
  background: #055afe;
  cursor: pointer;
  color: #fff;
  margin: 0 auto;
  margin-top: 60px;
}


.el-form {
  text-align: center;
}
.dialog_head {
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #055afe;
  margin-bottom: 20px;
}
.success_icon {
  width: 100px;
  height: 100px;
  margin: 0 auto;
}

.success_icon img {
  display: block;
  width: 100%;
  height: 100%;
}

.success_title {
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #000000;
  margin: 30px 0;
}

.success_content {
  width: 100%;
  text-align: center;
  font-size: 18px;
  color: #919191;
}
.success_content p span {
  color: #055AFE;
}
</style>
